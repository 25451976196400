export interface SiteMap {
  modules: SiteModule[]
}

export interface SiteModule {
  key: string,
  level?: string,
  name?: string,
}

export const DefaultSiteMap = {
  modules: [
    // { key: 'blog' },
    { key: 'coursePlan' },
    // { key: 'eLearning', level: 'subscriptionbased' },
    // { key: 'eLearningV2', level: 'bas' },
    // { key: 'payroll' },
    // { key: 'knowledge', level: 'individual' },
    // { key: 'forum', level: 'open' },
  ],
}